import { post } from '@/utils/request';

// 新增旧料转金料
const createDataReq = (params) => post({
  url: '/store/material.OldMaterialGold/createData',
  data: {
    ...params,
  },
});

// 材质成色列表
const getMaterialListReq = () => post({
  url: '/store/common.Select/getMaterialList',
  data: {
    isAll: 1,
  },
});

// 金料列表
const getListReq = (params) => post({
  url: '/store/material.OldMaterialGold/getList',
  data: {
    ...params,
  },
});

// 状态修改
const handleAuditReq = (params) => post({
  url: '/store/material.OldMaterialGold/handleAudit',
  data: {
    ...params,
  },
});

// 金料入库详情
const getDetailReq = (params) => post({
  url: '/store/material.OldMaterialGold/getGemAll',
  data: {
    ...params,
  },
});

// 金料入库编辑（驳回）
const updateDateReq = (params) => post({
  url: '/store/material.OldMaterialGold/updateData',
  data: {
    ...params,
  },
});

// 金料入库编辑（反确认）
const backUpdateDateReq = (params) => post({
  url: '/store/material.OldMaterialGold/backUpdateData',
  data: {
    ...params,
  },
});

// 金料入库（不通过删除）
const handleDeleteReq = (params) => post({
  url: '/store/material.OldMaterialGold/handleDelete',
  data: {
    ...params,
  },
});

// 反确认删除
const handleBackDeleteReq = (params) => post({
  url: '/store/material.OldMaterialGold/handleBackDelete',
  data: {
    ...params,
  },
});

export {
  createDataReq,
  handleBackDeleteReq,
  handleDeleteReq,
  backUpdateDateReq,
  updateDateReq,
  getDetailReq,
  handleAuditReq,
  getListReq,
  getMaterialListReq,
};
