<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" :content="content"> </el-page-header>
    </div>
    <div class="box">
      <el-button
        type="primary"
        style="margin: 10px 0"
        @click="rawVisibel = true"
        v-if="type === 'add' || type === 'edit'"
        >选择旧料</el-button
      >
      <div style="margin-top: 10px;">
        <div
          class="wrapper"
          v-for="(item, index) in rawGoods"
          :key="'raw' + index"
          v-if="item.goods.length"
        >
          <template>
            <div class="title">{{ item.goods[0]["attr_100"] || '--' }}</div>
            <div class="table">
              <el-table
                :data="item.goods"
                style="width: 100%"
                stripe
                v-loading="loading"
                :row-class-name="picRowClassName"
              >
                <template v-for="item in rowList">
                  <el-table-column
                    :key="item.field_alias"
                    :prop="item.field_alias"
                    :label="item.field_text"
                    align="center"
                    :width="item.t_width"
                  >
                    <template slot="header" slot-scope="scope">
                      <span :key="item.field_alias">
                        <span v-if="item.require" style="color: #f56c6c">*</span>
                        {{ item.field_text }}
                      </span>
                    </template>
                    <template slot-scope="scope">
                      {{
                        (scope.row[item.field_alias] || scope.row[item.field_alias] === 0)
                          ? scope.row[item.field_alias]
                          : "--"
                      }}
                    </template>
                  </el-table-column>
                </template>
                <el-table-column label="操作" align="center" width="180px" v-if="type === 'add' || type === 'edit'">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      style="color: #f56c6c"
                      @click="handleDele(index, scope.row.index, scope.row.goods_id)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <div class="bottom" v-if="item.goods.length">
                <span class="txt">转料</span>
                <el-form :model="item" inline label-suffix="：" label-width="100px">
                  <el-form-item label="总净金重">
                    {{item.gold_weight}}
                    <!-- <el-input
                      v-model="item.gold_weight"
                      disabled
                      placeholder="请填写净金重"
                    ></el-input> -->
                  </el-form-item>
                  <el-form-item label="数量">
                    {{item.num}}
                  </el-form-item>
                  <el-form-item label="总回收金额">
                    <!-- <el-input disabled v-model="item.amount_money"></el-input> -->
                    {{item.amount_money}}
                  </el-form-item>
                  <el-form-item label="单价">
                    {{item.unit_price}}
                    <!-- <el-input
                    :disabled="type === 'detail' || type === 'examine' ? true : false"
                    type="number"
                      @input="handleInput($event, item)"
                      v-model="item.unit_price"
                      placeholder="请填写单价"
                    ></el-input> -->
                  </el-form-item>
                  <el-form-item label="备注">
                    <el-input
                      :disabled="type === 'detail' || type === 'examine' ? true : false"
                      type="textarea"
                      style="width: 300px"
                      v-model="item.remark"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </template>
        </div>
      </div>
      <el-form :model="form" inline>
        <el-form-item label="创建时间" v-if="type === 'edit' && row.status === 30">
          <el-date-picker
            v-model="form.create_time"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="审核时间" v-if="type === 'edit' && row.status === 30">
          <el-date-picker
            v-model="form.audit_time"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="btn" v-if="type === 'add' || type === 'edit'">
        <el-button type="primary" @click="onSave" :loading="saveLoading">保存</el-button>
      </div>
      <div class="btn" v-if="type === 'examine'">
        <el-button type="primary" @click="handleAudit(30)"
            >审核通过</el-button
          >
          <el-button @click="handleAudit(20)">审核不通过</el-button>
      </div>
    </div>
    <choose-raws
      v-if="rawVisibel"
      :rawVisibel="rawVisibel"
      @close="handleClose"
      @getGoods="handleGoods"
      :selectGoods="goodList"
    />
  </div>
</template>

<script>
import { createDataReq, getDetailReq, handleAuditReq, updateDateReq, backUpdateDateReq } from '@/api/goods/raw/toGold';
import chooseRaws from "@/components/goods/chooseRaws/Index.vue";
import numeral from "numeral";
import moment from "moment";

export default {
  data() {
    return {
      oldGoodList: [],
      goodList: [],
      saveLoading: false,
      rawVisibel: false,
      goodVisibel: false,
      loading: false,
      rawGoods: [],
      form: {
        attr_104: '0.000',
        create_time: '',
        audit_time: '',
      },
      rowList: [
        { field_text: "序号", field_alias: "index" },
        { field_text: "货号", field_alias: "goods_number" },
        { field_text: "品名", field_alias: "goods_name" },
        { field_text: "净金重", field_alias: "attr_104" },
        { field_text: "数量", field_alias: "num" },
        { field_text: "回收单价", field_alias: "attr_1449" },
        { field_text: "回收金额", field_alias: "recycle_price" },
        { field_text: "回收时间", field_alias: "create_time" },
        { field_text: "状态", field_alias: "status" },
      ],
      formItemList: [
        {
          label: "品类",
          placeholder: "请选择",
          attr: "goods_type_id",
          type: "treeSelect",
        },
        {
          label: "货号",
          placeholder: "请填写货号",
          attr: "goods_number",
          type: "input",
        },
        {
          label: "入库单号",
          placeholder: "请填写入库单号",
          attr: "entry_number",
          type: "input",
        },
        {
          label: "盘点单号",
          placeholder: "请填写盘点单号",
          attr: "check_number",
          type: "input",
        },
        {
          label: "调拨单号",
          placeholder: "请填写调拨单号",
          attr: "allot_number",
          type: "input",
        },
        {
          label: "修改单号",
          placeholder: "请填写修改单号",
          attr: "edit_number",
          type: "input",
        },
        {
          label: "款号",
          placeholder: "请填写入款号",
          attr: "goods_style_number",
          type: "input",
        },
        {
          label: "品名",
          placeholder: "请填写入品名",
          attr: "goods_name",
          type: "input",
        },
        { type: "hierarchy_id", label: "所属门店" },
        { type: "warehouse_id", label: "仓库" },
        { type: "101", label: "款式" },
        { type: "100", label: "材质成色" },
        { type: "103", label: "品牌" },
        { type: "sale_price", label: "标签价" },
        { type: "104", label: "净金重" },
        { type: "entry_time", label: "入库时间" },
        // {
        //   label: "调拨单号",
        //   placeholder: "请填写调拨单号",
        //   attr: "allot_id",
        //   type: "input",
        // },
      ],
    };
  },
  props: {
    row: Object,
    type: String,
  },
  components: {
    chooseRaws,
  },
  created() {
    if (this.type === 'add') {
      this.rawVisibel = true;
      this.content = '新增旧料转金料';
    }
    if (this.type === 'detail') {
      this.content = '旧料转金料详情';
    }
    if (this.type === 'examine') {
      this.content = '旧料转金料审核';
    }
    if (this.type === 'edit') {
      this.content = '旧料转金料编辑';
    }
    if (this.type === 'detail' || this.type === 'examine' || this.type === 'edit') {
      this.getDetail();
    }
  },
  methods: {
    picRowClassName({ row, rowIndex }) {
      // 给每一行添加索引
      row.index = rowIndex + 1;
    },
    // 修改状态
    handleAudit(status) {
      let content = '';
      if (status === 30) {
        content = '确定要审核通过吗？'
      }
      if (status === 20) {
        content = '确定要审核不通过吗？'
      }
      this.$confirm(content, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          handleAuditReq({
            material_entry_id: this.row.material_entry_id,
            status,
          })
            .then((res) => {
              if (res.code === 1) {
                this.$message.success("操作成功");
                this.$emit('onBack', 'getList');
              }
            })
            .catch(() => {
              this.currRow.select = "";
            });
        })
        .catch(() => {});
    },
    getDetail() {
      getDetailReq({
        material_entry_id: this.row.material_entry_id,
      })
        .then((res) => {
          if (res.code === 1) {
            res.data.list.forEach((item) => {
              item.goods.forEach((good) => {
                this.goodList.push({
                  ...good,
                  material_id: item.attr_value_id,
                });
              });
            });
            this.rawGoods = res.data.list;
            this.form.create_time = res.data.create_time;
            this.form.audit_time = res.data.audit_time;
          }
        });
    },
    onSave() {
      if (!this.rawGoods.length) {
        this.$message.warning('请选择旧料');
        return;
      }
      for (let i = 0; i < this.rawGoods.length; i += 1) {
        const item = this.rawGoods[i];
        if (!item.unit_price) {
          this.$message.error('单价为必填值');
          return;
        }
        if (Number(item.unit_price) < 0) {
          this.$message.error('单价不能小于0');
          return;
        }
      }
      this.saveLoading = true;
      if (this.type === 'add') {
        createDataReq({
          data_count: JSON.stringify(this.rawGoods),
        })
          .then((res) => {
            this.saveLoading = false;
            if (res.code === 1) {
              this.$message.success('操作成功');
              this.$emit('onBack', 'getList');
            }
          })
          .catch(() => {
            this.saveLoading = false;
          });
      }
      // 驳回编辑
      if (this.type === 'edit' && this.row.status !== 30) {
        updateDateReq({
          material_entry_id: this.row.material_entry_id,
          data_count: JSON.stringify(this.rawGoods),
        }).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.$emit("onBack", "getList");
          }
        })
          .catch(() => {
            this.saveLoading = false;
          });
      }
      // 反确认编辑
      if (this.type === 'edit' && this.row.status === 30) {
        backUpdateDateReq({
          material_entry_id: this.row.material_entry_id,
          data_count: JSON.stringify(this.rawGoods),
          create_time: this.form.create_time,
          audit_time: this.form.audit_time,
        }).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.$emit("onBack", "getList");
          }
        })
          .catch(() => {
            this.saveLoading = false;
          });
      }
    },
    handleInput(e, item) {
      item.amount_money = Number(item.gold_weight) * Number(e);
      item.amount_money = numeral(item.amount_money).format('0.00');
    },
    getSummaries(param) {
      const { columns, data } = param;
      let attr_104 = 0;
      let num = 0;
      let recycle_price = 0;
      const sums = ['合计'];
      data.forEach((item, index) => {
        attr_104 += Number(item['attr_104'])
        num += Number(item.num)
        recycle_price += Number(item.recycle_price)
      });
      attr_104 = numeral(attr_104).format('0.000');
      recycle_price = numeral(recycle_price).format('0.00');
      this.form.gold_weight = attr_104;
      sums[3] = attr_104;
      sums[4] = num;
      sums[6] = recycle_price;
      return sums;
    },
    handleClose() {
      this.rawVisibel = false;
    },
    handleDele(index, samllIndex, goods_id) {
      this.goodList.forEach((item, i) => {
        if (item.goods_id === goods_id) {
          this.goodList.splice(i, 1);
        }
      });
      this.rawGoods[index].goods.splice(samllIndex - 1, 1);
      this.rawGoods.forEach((item, goodIndex) => {
        if (!item.goods.length) {
          this.rawGoods.splice(goodIndex, 1);
        }
      });
      this.handleSum();
    },
    goBack() {
      this.$emit("onBack");
    },
    handleGoods(goods) {
      this.oldGoodList = this.goodList;
      this.goodList = [...this.goodList, ...goods];
      // 将数组按照材质成色切分成二维数组（attr_100）
      let obj2 = {};
      let arr = [];
      this.goodList.forEach((item) => {
        const key = item["attr_100"];
        if (!obj2[key] && obj2[key] !== 0) {
          obj2[key] = Object.keys(obj2).length;
          arr[obj2[key]] = {
            attr_value_id: item.material_id,
            gold_weight: 0,
            unit_price: '',
            amount_money: '',
            remark: '',
            goods: [],
          };
          arr[obj2[key]].goods.push(item);
        } else {
          if (this.type === 'edit') {
            // 需要判断
            let m = 0;
            this.oldGoodList.forEach((good) => {
              if (good.goods_id !== item.goods_id) {
                m += 1;
                if (m === this.oldGoodList.length) {
                  item.old_goods_id = 0;
                  console.log('触发');
                }
              }
            });
          }
          arr[obj2[key]].goods.push(item);
        }
      });
      if (this.rawGoods) {
        arr.forEach((arrItem) => {
          let m = 0;
          this.rawGoods.forEach((raw) => {
            if (arrItem.attr_value_id === raw.attr_value_id) {
              arrItem.attr_value_id = raw.attr_value_id;
              // arrItem.gold_weight = raw.gold_weight;
              arrItem.unit_price = raw.unit_price;
              // arrItem.amount_money = raw.amount_money;
              arrItem.remark = raw.remark;
              arrItem.material_entry_goods_id = raw.material_entry_goods_id;
            } else {
              m += 1;
              if (m === this.rawGoods.length) {
                arrItem.material_entry_goods_id = 0;
              }
            }
          })
        })
        this.rawGoods = arr;
      } else {
        this.rawGoods = arr;
      }
      this.rawGoods = arr;
      this.handleSum();
    },
    handleSum() {
      // 合计净金重
      console.log(this.rawGoods);
      this.rawGoods.forEach((item) => {
        item.gold_weight = 0;
        item.num = 0;
        item.amount_money = 0;
        item.goods.forEach((good) => {
          if (!Number(good.attr_104)) {
            good.attr_104 = 0;
          }
          item.gold_weight += (Number(good.attr_104) * Number(good.num));
          if (!Number(good.num)) {
            good.num = 0;
          }
          item.num += Number(good.num);
          if (!Number(good.recycle_price)) {
            good.recycle_price = 0;
          }
          item.amount_money += (Number(good.recycle_price) * Number(good.num));
        });
        item.gold_weight = numeral(item.gold_weight).format('0.000');
        // item.num = numeral(item.num).format('0');
        item.amount_money = numeral(item.amount_money).format('0.00');
        // if (item.unit_price) {
        //   item.amount_money = Number(item.gold_weight) * Number(item.unit_price);
        //   item.amount_money = numeral(item.amount_money).format('0.00');
        // }
        item.unit_price = Number(item.amount_money) / Number(item.gold_weight);
        item.unit_price = numeral(item.unit_price).format('0.00');
      });
    },
  },
};
</script>

<style lang="less" scoped>
// /deep/ .el-input.is-disabled .el-input__inner {
//   background: white;
// }
// /deep/ .el-textarea.is-disabled .el-textarea__inner {
//   background: white;
// }
.bottom {
  padding: 20px;
}
.wrapper {
  padding: 20px;
  background: white;
}
.btn {
  border-top: 1px solid #ddd;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  margin-bottom: 20px;
}
.box {
  // background: white;
  // padding: 20px;
  margin-bottom: 80px;
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
}
.txt {
  font-weight: bold;
}
.title {
  background: white;
  border: 1px solid #ddd;
  line-height: 30px;
  font-weight: bold;
  text-indent: 20px;
}
/deep/ .table .el-input__inner {
  text-align: left;
}
</style>
