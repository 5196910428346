<template>
  <el-dialog
    title="筛选旧料"
    :visible.sync="rawVisibel"
    width="80%"
    :before-close="handleClose"
  >
    <div>
      <div>
        <Cond
          @onCond="handleCond"
          function_code="recycleGoodsList"
          @setCond="handleGetCond"
          :show="false"
        />
        <TableConf
          function_code="recycleGoodsList"
          @onRowList="handleRowList"
          :show="false"
        />
      </div>
      <div class="table" v-if="rowList.length">
        <!-- <div class="total">
            <div class="total-right">
              <div class="total-right-item">
                总金重 <span>{{ goodsTotal.goldweight }}</span>
              </div>
              <div class="total-right-item">
                数量 <span>{{ goodsTotal.pcs }}</span>
              </div>
              <div class="total-right-item">
                回收总金额 <span>{{ goodsTotal.recycle_price }}</span>
              </div>
            </div>
          </div> -->
        <el-table
          use-virtual
          row-key="goods_id"
          :data="tableData"
          stripe
          height="300"
          @selection-change="handleSelectionChange"
          ref="multipleTable"
          style="margin-top: 10px"
          v-loading="loading"
        >
          <el-table-column
            type="selection"
            align="center"
            :reserve-selection="true"
            width="55"
            fixed="left"
            :selectable="handleSelectable"
          >
          </el-table-column>
          <template v-for="(item, index) in rowList">
            <el-table-column
              v-if="item.filed_status"
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              :filters="item.filed_status"
              :filter-method="filterHandler"
            >
            </el-table-column>
            <el-table-column
              v-else-if="item.field_alias === 'goods_name'"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  {{ scope.row[item.field_alias] || "--" }}
                  <!-- <div
                      v-if="scope.row.goods_tag_pic.length !== 0"
                      style="display: flex; margin-left: 5px"
                    >
                      <template v-for="item in scope.row.goods_tag_pic">
                        <img :src="item" :key="item" style="margin-right: 5px" />
                      </template>
                    </div> -->
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <div v-if="item.field_alias === 'goods_pic'">
                  <img
                    :src="scope.row.goods_pic"
                    v-if="scope.row.goods_pic"
                    class="image-slot"
                  />
                  <div slot="error" class="image-slot" v-else>
                    <img :src="noImg" />
                  </div>
                </div>
                <div v-else-if="item.field_alias === 'warning_state'">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-if="
                      scope.row[item.field_alias] !== 10 &&
                      scope.row[item.field_alias] !== 20 &&
                      scope.row[item.field_alias] !== 30
                    "
                  >
                    <div
                      style="
                        background-color: #21bc36;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-else-if="scope.row[item.field_alias] === 10"
                  >
                    <div
                      style="
                        background-color: #ffdf04;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-else-if="scope.row[item.field_alias] === 20"
                  >
                    <div
                      style="
                        background-color: #ff9000;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-else-if="scope.row[item.field_alias] === 30"
                  >
                    <div
                      style="
                        background-color: #f4390d;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                </div>
                <div v-else>
                  {{ scope.row[item.field_alias] || "--" }}
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <div class="pagina" v-if="rowList.length">
        <el-pagination
          :current-page="cond.page"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="$store.state.pageSizes[0]"
          :page-sizes="$store.state.pageSizes"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
      <!-- <el-button
        plain
        style="margin-top: 10px"
        size="mini"
        type="primary"
        @click="handleAll"
        :loading="allLoading"
        >所有全选</el-button
      > -->
      <div class="title">
      已添加的旧料
      <el-button
        plain
        style="margin-left: 10px"
        size="mini"
        @click="handleClear"
        >清空旧料</el-button
      >
    </div>
      <div class="table" v-if="rowList.length">
        <!-- <div class="total">
            <div class="total-right">
              <div class="total-right-item">
                总金重 <span>{{ goodsTotal.goldweight }}</span>
              </div>
              <div class="total-right-item">
                数量 <span>{{ goodsTotal.pcs }}</span>
              </div>
              <div class="total-right-item">
                回收总金额 <span>{{ goodsTotal.recycle_price }}</span>
              </div>
            </div>
          </div> -->
        <el-table
          use-virtual
          row-key="goods_id"
          :data="addList"
          stripe
          height="300"
          @selection-change="handleSelectionChange"
          ref="multipleTable"
          style="margin-top: 10px"
        >
          <template v-for="(item, index) in rowList">
            <el-table-column
              v-if="item.filed_status"
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              :filters="item.filed_status"
              :filter-method="filterHandler"
            >
            </el-table-column>
            <el-table-column
              v-else-if="item.field_alias === 'goods_name'"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  {{ scope.row[item.field_alias] || "--" }}
                  <!-- <div
                      v-if="scope.row.goods_tag_pic.length !== 0"
                      style="display: flex; margin-left: 5px"
                    >
                      <template v-for="item in scope.row.goods_tag_pic">
                        <img :src="item" :key="item" style="margin-right: 5px" />
                      </template>
                    </div> -->
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <div v-if="item.field_alias === 'goods_pic'">
                  <img
                    :src="scope.row.goods_pic"
                    v-if="scope.row.goods_pic"
                    class="image-slot"
                  />
                  <div slot="error" class="image-slot" v-else>
                    <img :src="noImg" />
                  </div>
                </div>
                <div v-else-if="item.field_alias === 'warning_state'">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-if="
                      scope.row[item.field_alias] !== 10 &&
                      scope.row[item.field_alias] !== 20 &&
                      scope.row[item.field_alias] !== 30
                    "
                  >
                    <div
                      style="
                        background-color: #21bc36;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-else-if="scope.row[item.field_alias] === 10"
                  >
                    <div
                      style="
                        background-color: #ffdf04;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-else-if="scope.row[item.field_alias] === 20"
                  >
                    <div
                      style="
                        background-color: #ff9000;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-else-if="scope.row[item.field_alias] === 30"
                  >
                    <div
                      style="
                        background-color: #f4390d;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                </div>
                <div v-else>
                  {{ scope.row[item.field_alias] || "--" }}
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <div class="btns">
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleTrue">确 定</el-button>
        </span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Cond from "@/components/cond/Index.vue";
import TableConf from "@/components/tableConf/Index.vue";

import {
  recycleList,
  getRecycleTotal,
  exportList,
  getRecycleGoodsPrintReq,
} from "@/api/goods/goodsStock/goodsOldDetailedList/index";

export default {
  data() {
    return {
      addList: [],
      allLoading: false,
      multipleSelection: [],
      noImg: require("@/assets/images/no_img.png"),
      cond: {
        page: 1,
        page_num: 50,
      },
      rowList: [],
      goodsTotal: {},
      tableData: [],
      total: 0,
    };
  },
  props: {
    rawVisibel: Boolean,
    selectGoods: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    selectGoodsIds() {
      const arr = this.selectGoods;
      let selectGoodsIds = [];
      arr.forEach((item) => {
        selectGoodsIds.push(item.goods_id);
      });
      return selectGoodsIds;
    },
  },
  components: {
    Cond,
    TableConf,
  },
  created() {
    this.getList();
    this.getRecycleTotal();
  },
  watch: {
    multipleSelection: {
      handler(val) {
        const arr = JSON.parse(JSON.stringify(val));
        arr.forEach((item, index) => {
          item.index = index + 1;
        });
        this.addList = arr;
      },
      deep: true,
    },
  },
  methods: {
    handleSelectable(row) {
      if (row.disables === true) {
        return false;
      }
      // 已选择的货品置灰
      for (let i = 0; i < this.selectGoodsIds.length; i += 1) {
        const item = this.selectGoodsIds[i];
        if (item == row.goods_id) {
          return false;
        }
      }
      return true;
    },
    handleClear() {
      this.$confirm("确定要清空所有货品吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.addList = [];
          this.$refs.multipleTable.clearSelection();
        })
        .catch(() => {
          console.log("取消");
        });
    },
    handleTrue() {
      if (!this.addList.length) {
        this.$message.warning("请添加货品");
        return;
      }
      this.handleClose();
      this.$emit("getGoods", this.addList);
    },
    handleAll() {},
    handleClose() {
      console.log('触发');
      this.$emit("close");
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /*
     *  分页操作
     */
    handleCurrentChange(val) {
      this.cond.page = val;
      this.loading = true;
      const data = {
        page: val,
        page_num: 50,
        ...this.cond,
        disables: true,
      };

      recycleList(data).then((res) => {
        if (res.code === 1) {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
      this.getRecycleTotal();
    },
    /*
     *  每页条数
     */
    handleSizeChange(val) {
      this.loading = true;
      this.cond.page_num = val;
      const data = {
        page: this.cond.page,
        page_num: this.cond.page_num,
        ...this.cond,
        disables: true,
      };
      recycleList(data).then((res) => {
        if (res.code === 1) {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
      this.getRecycleTotal();
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    /*
     *  获取货品清单数据
     */
    getList() {
      this.visiable = false;
      this.loading = true;
      recycleList({ ...this.cond, disables: true }).then((res) => {
        if (res.code === 1) {
          this.loading = false;
          res.data.list.map((item) => {
            for (var key in item) {
              if (key !== "goods_pic") {
                if (!item[key]) {
                  item[key] = "--";
                }
              }
            }
            return item;
          });
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    getRecycleTotal() {
      getRecycleTotal({ ...this.cond }).then((res) => {
        if (res.code === 1) {
          this.goodsTotal = res.data;
        }
      });
    },
    handleCond(cond) {
      this.cond = {
        ...cond,
        page: 1,
        page_num: this.cond.page_num,
      };
      this.getList();
      this.getRecycleTotal();
    },
    handleGetCond(cond) {
      this.cond = cond;
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  line-height: 60px;
  font-weight: bold;
  font-size: 16px;
  color: rgb(80, 80, 80);
}
.btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
/deep/ .image-slot {
  width: 54px;
  height: 54px;
  margin: 5px auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
}
.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  // padding: 0 20px;
  background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border: 1px solid #ddd;
  border-top: none;
  border-left: none;
  border-right: none;

  // border-radius: 0px 0px 6px 6px;
  &-left {
    // flex-shrink: 0;
  }
  &-right {
    display: flex;
    flex: 1;
    &-item {
      font-size: 15px;
      // padding: 0 10px;
      width: 25%;
      // border-right: 1px solid #ddd;
      text-align: center;
      color: #909399;
      span {
        font-size: 16px;
        color: #e7541e;
      }
    }
  }
}
</style>
